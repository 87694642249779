import {
  Avatar,
  Heading,
  HStack,
  LightMode,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { SubscriptionPaymentForm } from "../SubscriptionPaymentForm";
import { useStoreState } from "@/store";

type PaymentModalProps = {
  title?: string;
  isOpen: boolean;
  onClose: () => void;
};

const stripePromise = loadStripe(
  process.env.NODE_ENV === "production"
    ? "pk_live_rJBxCS34TPDZuQNJZNIUUkaM00exP7KUdk"
    : "pk_test_SXdBkm8HcUBAftGyfcFjX9yg00ohkE7tVO"
);

export const PaymentModal = ({ title, isOpen, onClose }: PaymentModalProps) => {
  const currentUser = useStoreState((state) => state.currentUser.value);
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent bgColor="purple.900">
        <ModalHeader>Upgrade</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack align="center" mb={4}>
            <Avatar
              name={currentUser?.firstName}
              src={currentUser?.photoUrl ?? undefined}
              size="xl"
              color="white"
            >
              <LightMode>
                <Tag
                  size="md"
                  colorScheme="purple"
                  pos="absolute"
                  top={-3}
                  textTransform="capitalize"
                >
                  Pro
                </Tag>
              </LightMode>
            </Avatar>
            <HStack>
              <Heading alignItems="center">
                $5
                <span style={{ fontSize: "16px" }}> / month</span>
              </Heading>
            </HStack>

            <List spacing="3">
              <ListItem>🔥 Unlimited rooms</ListItem>
              <ListItem>🔥 Up to 20 participants per room</ListItem>
              <ListItem>🔥 Unlock "Pro" badge</ListItem>
            </List>
            <Text color="gray.600">No obligation. Cancel any time.</Text>
          </Stack>

          <Elements stripe={stripePromise}>
            <SubscriptionPaymentForm onPaymentSuccess={onClose} />
          </Elements>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};
