const axios = require("axios").default;
import { Stack, Input, Button, Box, Flex, useToast } from "@chakra-ui/react";
import { captureException } from "@sentry/minimal";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { Toast } from "./Toast";
import { useStoreActions, useStoreState } from "@/store";
import { getFirebaseCloudFunctionsBaseUrl } from "@/services/utils";

type Props = {
  onPaymentSuccess: () => void;
};

export const SubscriptionPaymentForm = ({ onPaymentSuccess }: Props) => {
  const currentUser = useStoreState((state) => state.currentUser.value);
  const setUser = useStoreActions((action) => action.currentUser.setUser);

  const stripe = useStripe();
  const elements = useElements();
  const [billing_details, setBillingDetails] = useState({
    name: currentUser?.firstName ?? "",
    email: currentUser?.email,
  });
  const toast = useToast();

  const [paymentLoading, setPaymentLoading] = useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setPaymentLoading(true);

    if (!stripe || !elements) return;
    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement!,
      billing_details,
    });

    if (error) {
      console.log("[error]", error);
    } else {
      if (!paymentMethod) return;
      console.log("[PaymentMethod]", paymentMethod);
      createSubscription({
        paymentMethodId: paymentMethod.id,
      });
    }
  };

  const createSubscription = async ({
    paymentMethodId,
  }: {
    paymentMethodId: string;
  }) => {
    if (!currentUser) return;
    try {
      let params = `?email=${billing_details.email}&name=${billing_details.name}&paymentMethodId=${paymentMethodId}&userId=${currentUser.id}`;
      if (currentUser.stripeCustomerId) {
        params += `&customerId=${currentUser.stripeCustomerId}`;
      }
      const { data } = await axios.get(
        `${getFirebaseCloudFunctionsBaseUrl()}/createSubscription${params}`
      );
      if (data.error || data.status !== "active") {
        throw data;
      }
      setUser({ ...currentUser, plan: "pro" });
      toast({
        render: () => (
          <Toast type="success">You've unlocked pro features 🔥</Toast>
        ),
      });
      onPaymentSuccess();
    } catch (err) {
      console.error("Payment failed:", err);
      captureException(err);
      toast({ render: () => <Toast>Payment failed.</Toast> });
    }
    setPaymentLoading(false);
  };

  return (
    <Stack as="form" onSubmit={handleSubmit}>
      <Input
        placeholder="Full Name"
        value={billing_details.name}
        onChange={(e) =>
          setBillingDetails({ ...billing_details, name: e.target.value })
        }
      />
      <Input
        placeholder="Email"
        value={billing_details.email}
        onChange={(e) =>
          setBillingDetails({ ...billing_details, email: e.target.value })
        }
      />
      <Box
        py={3}
        px={3}
        borderColor="rgba(255, 255, 255, 0.16)"
        borderWidth="1px"
        rounded="lg"
      >
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "16px",
                backgroundColor: "transparent",
                padding: "2rem",
                color: "white",
                "::placeholder": {
                  color: "#adadad",
                },
              },
              invalid: {
                color: "#9e2146",
              },
            },
          }}
        />
      </Box>

      <Flex justifyContent="flex-end">
        <Button type="submit" isDisabled={!stripe} isLoading={paymentLoading}>
          Pay
        </Button>
      </Flex>
    </Stack>
  );
};
