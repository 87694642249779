import { Box, Text } from "@chakra-ui/react";
import React from "react";

type ToastProps = {
  type?: "error" | "success";
  children: React.ReactNode;
};

export const Toast = ({ children, type = "error" }: ToastProps) => {
  return (
    <Box
      p={4}
      bgColor={type === "error" ? "orange.400" : "purple.400"}
      rounded={4}
    >
      <Text fontWeight="bold">{children}</Text>
    </Box>
  );
};
